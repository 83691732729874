import React from 'react'
import '../styles/global.css'

interface LayoutProps {
  children: React.ReactNode
}

const Layout: React.FC<LayoutProps> = ({ children }) => <div className="flex-grow">{children}</div>

export default Layout
